<template>
  <custom-dialog
    :dialog="dialog"
    @outsideClick="$emit('update:dialog', false)"
  >
      <DialogTitle :title='$vuetify.lang.t("$vuetify.departmenInfo")'/>

      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-text-field
            outlined
            :label="$vuetify.lang.t('$vuetify.departName')"
            :rules="[rule.required]"
            v-model="department.name"
          />

          <v-select
            outlined
            :items="users"
            :label="$vuetify.lang.t('$vuetify.departmentHead')"
            item-text="name"
            item-value="id"
            v-model="department.leader"
            clearable
          />

          <v-text-field
            outlined
            :label="$vuetify.lang.t('$vuetify.Sorting')"
            v-model="department.order"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-6">
        <v-spacer />

        <v-btn @click="resetForm" color="red" outlined>
          {{ $vuetify.lang.t("$vuetify.shut") }}
        </v-btn>

        <v-btn @click="validate" color="primary">
          {{ $vuetify.lang.t("$vuetify.Confirm") }}
        </v-btn>
      </v-card-actions>

  </custom-dialog>
</template>

<script>
import { draggable } from "@/api/customFunction";

export default {
  data() {
    return {
      valid: false,
      rule: {
        required: (v) => !!v || "this field is required",
      },
    };
  },
  props: {
    department: Object,
    dialog: Boolean,
    users: Array,
  },
  components: {
    DialogTitle: () => import("@/components/dialogTitle"),
  },
  methods: {
    drag() {
      draggable();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("handleData", this.department);
        this.$emit("update:dialog", false);
      }
    },
    resetForm() {
      this.$refs.form.resetValidation();
      if (!this.department.leader) {
        this.department.leader = {
          user_id: this.department.leader,
        };
      }
      this.$emit("update:dialog", false);
    },
  },
};
</script>

<style>
</style>